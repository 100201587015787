import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h4>{`Distinctions`}</h4>
    <p><strong parentName="p">{`Noble Kaplan`}</strong>{` `}<inlineCode parentName="p">{`jsx:<D8/>`}</inlineCode>{`
Jules is a member of the `}<em parentName="p">{`Kaplans`}</em>{`, a family with a storied history in Aljiedum with a great deal of wealth and influence. Jules is not afraid to use this influence as it suits him.`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Hinder`}</em>{`: Gain one PP when you switch out this distinction's die rating for a `}<inlineCode parentName="li">{`jsx:<D4/>`}</inlineCode></li>
      <li parentName="ul"><em parentName="li">{`Don't you know who I am?`}</em>{`: Spend a PP to double this trait when attempting to use your family influence to accomplish something.`}</li>
    </ul>
    <p><strong parentName="p">{`I Go My Own Way`}</strong>{` `}<inlineCode parentName="p">{`jsx:<D8/>`}</inlineCode>{`
Jules has never followed the path set out for him. He has always forged his own way in life, often shirking the course his family created for him.`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Hinder`}</em>{`: Gain one PP when you switch out this distinction's die rating for a `}<inlineCode parentName="li">{`jsx:<D4/>`}</inlineCode></li>
    </ul>
    <p><strong parentName="p">{`Top Student`}</strong>{` `}<inlineCode parentName="p">{`jsx:<D8/>`}</inlineCode>{`
Jules found that he had a natural affinity for Luminous Magic, and has exceeded at all of his studies as an Acronist.`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Hinder`}</em>{`: Gain one PP when you switch out this distinction's die rating for a `}<inlineCode parentName="li">{`jsx:<D4/>`}</inlineCode></li>
    </ul>
    <h4>{`Attributes`}</h4>
    <p><em parentName="p">{`Strength`}</em>{` `}<inlineCode parentName="p">{`jsx:<D4/>`}</inlineCode>{`
`}<em parentName="p">{`Dexterity`}</em>{` `}<inlineCode parentName="p">{`jsx:<D6/>`}</inlineCode>{`
`}<em parentName="p">{`Intellect`}</em>{` `}<inlineCode parentName="p">{`jsx:<D8/>`}</inlineCode>{`
`}<em parentName="p">{`Will`}</em>{` `}<inlineCode parentName="p">{`jsx:<D8/>`}</inlineCode>{`
`}<em parentName="p">{`Awareness`}</em>{` `}<inlineCode parentName="p">{`jsx:<D6/>`}</inlineCode>{`
`}<em parentName="p">{`Conviction`}</em>{` `}<inlineCode parentName="p">{`jsx:<D10/>`}</inlineCode></p>
    <h4>{`Values`}</h4>
    <p><em parentName="p">{`Responsibility`}</em>{` `}<inlineCode parentName="p">{`jsx:<D6/>`}</inlineCode>{`
I am responsible for my own success.
`}<em parentName="p">{`Power`}</em>{` `}<inlineCode parentName="p">{`jsx:<D8/>`}</inlineCode>{`
True power comes from knowing what your own greatest strengths are.
`}<em parentName="p">{`Honesty`}</em>{` `}<inlineCode parentName="p">{`jsx:<D6/>`}</inlineCode>{`
It is useful to know when to tell the truth, and when a lie will get you further.
`}<em parentName="p">{`Self`}</em>{` `}<inlineCode parentName="p">{`jsx:<D10/>`}</inlineCode>{`
I am the most important person in my life.
`}<em parentName="p">{`Knowledge`}</em>{` `}<inlineCode parentName="p">{`jsx:<D8/>`}</inlineCode>{`
The more I learn, the stronger I become.
`}<em parentName="p">{`Altruism`}</em>{` `}<inlineCode parentName="p">{`jsx:<D4/>`}</inlineCode>{`
The best way I can serve the world is by being the best person I can be.`}</p>
    <h4>{`Luminous Magic`}</h4>
    <p><strong parentName="p">{`Convictions of Adhesion`}</strong>{` `}<inlineCode parentName="p">{`jsx:<D8/>`}</inlineCode>{`
Jules has chosen to focus his studies as an Acronist on `}<em parentName="p">{`Adhesion Magic`}</em>{`. He excels at manifesting Convictions related to causing objects/materials to stick together or become frictionless. He can apply these convictions both inanimate objects and creatures.`}</p>
    <ul>
      <li parentName="ul"><em parentName="li">{`Fixed Truth`}</em>{`: When manifesting a conviction related to adhesion, spend a PP to create a `}<inlineCode parentName="li">{`jsx:<D8/>`}</inlineCode>{` asset out of the manifestation.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      